.Calendar{
  border-radius: 0.2em;
  background-color: white;
  width: 7em;
}

.Input-group-Icon{
  cursor: pointer;
  margin-left: 0.1em;
}
.Calendar-input{
  margin-bottom: 5px;
}
#page{
  width: 100%;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #866ec7;
  color: #fff;
  transition: all .3s;
  position: relative;
  position: sticky;
  top: 0px;
  height: 100vh;
}

#sidebar-content{
  height: 100%!important;
  overflow:auto;
}
#sidebar h1 .logo {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
}

#sidebar-collapse {
  background: #6749b9;
  border-color: #6749b9;
  width: 40px;
  border-radius: 50%;
}

#sidebar-collapse-wrapper{
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
}

#sidebar ul li {
  font-size: 16px
}

#sidebar ul li>ul {
  margin-left: 10px;
}

#sidebar ul li>ul li {
  font-size: 14px
}

#sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, .8);
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  text-decoration: none;
}

#sidebar ul li a:hover {
  color: #fff
}

#sidebar ul li.active>a {
  background: 0 0;
  color: #fff
}

#sidebar a[data-toggle=collapse] {
  position: relative;
}

#sidebar .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-left: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.e-Service{
  color: #541431;
  font-weight: bold;
}
.margintop{
  margin-top:1em ;
}
.paddingtop{
  padding-top:1em ;
}
.register-form {
  align-content: center;
  vertical-align: middle;
  min-width: 15em;  
}
.note
{
    text-align: center;
    height: 80px;
    background: -webkit-linear-gradient(left, #0072ff, #8811c5);
    color: #fff;
    font-weight: bold;
    line-height: 80px;
}
.form-content
{
    padding: 5%;
    border: 1px solid #ced4da;
    margin-bottom: 2%;
}
.form-control{
    border-radius:1.5rem;
}
.btnSubmit
{
    border:none;
    border-radius:1.5rem;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    background: #0062cc;
    color: #fff;
}
.tableview{
  border:groove;
  border-radius:.5rem;
}
.Header{
  background-color: dimgray;
  color: white;
  font: 2em sans-serif;
  border-top-left-radius:.5rem;
  border-top-right-radius:.5rem;
  text-align: center;
  padding-top: .5rem;
  margin-top: -1.5rem;
}
.datarow{
  background-color: rgb(240, 238, 247);
  color: black;
  border-bottom: 1px solid blue ;
  border-collapse: collapse;
  transition: background-color 0.3s; 
}


.datarowGreen {
  background-color:rgb(143, 223, 152);
  color:white;
}

.datarow :hover{
  background-color: rgb(118, 118, 243);
  color: white;
}
.center{
  text-align: center;
}
.right{
  text-align:right;
}
.FloatRight {
  float:right;
}

.bold12 {
  font: 1.2em sans-serif;
  min-width: 6em;
 }

 .bold_Notes {
  font-weight: bold;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: .45rem;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, .4);
  border-radius: 3px;
}
::-webkit-scrollbar-track{
  background: transparent;
}

#content{
  width:100%;
  margin-left:20px;
  margin-right: 12px;
}

.content-background {
  background: linear-gradient(to top, rgb(236, 233, 230), rgb(255, 255, 255))
}


.container2 {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}


.screen {		
	background: linear-gradient(90deg, #5D54A4, #7C78B8);		
	position: relative;	
	height: 600px;
	width: 360px;	
	box-shadow: 0px 0px 24px #5C5696;
}

.screen__content {
	z-index: 1;
	position: relative;	
	height: 100%;
  background-image: url(/public/loginPicture.PNG);
}



.login {
	width: 320px;
	padding: 30px;
	padding-top: 156px;
}

.login__field {
	padding: 20px 0px;	
	position: relative;	
}

.login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
}

.login__input {
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 75%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #4C489D;
	box-shadow: 0px 2px 2px #5C5696;
	cursor: pointer;
	transition: .2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}

.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875B5;
}

.social-login {	
	position: absolute;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;	
	text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
	transform: scale(1.5);	
}